import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
})
export class AdminComponent implements OnInit {
  constructor(private router: Router) {}

  currentRoute: string = '';  
  ngOnInit(): void {
    // console.log(this.router.url);
    this.currentRoute = this.router.url;
    this.router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
    })
  }
}
