import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "app-card-new-order",
  templateUrl: "./card-new-order.html",
  styleUrls:["card-new-order.scss"]
})
export class CardNewOrderComponent implements OnInit {
  listJob: any = [];
  object: any = {
    selectJob : null
  };
  // listSubdomain: any = [];
  arrOfPub: any = [];
  selectedPublisher: any;
  domainList: any = [];
  website_id: any = -1;
  website_list: any = [];
  loggedIn: any;
  firstJobExists: any = false;
  detailToFill: any = [];
  isAppsOnly = false;

  dropdownList = [];
  dropdownSettings = {};
  selectedItems = [];

  constructor(
    private orderTrackingService: OrderTrackingService,
    private pubService: PublisherService,
    private toastr: ToastrService,
    private singletonServices: SingletonServices
  ) {}

  onPubSelect(item: any) {
    this.website_id = [];
    this.refetchDomainlist()
  }


  listenChange(event, row) {

    row.value = event;
  }

  onPubDeSelect(item: any) {
    this.refetchDomainlist();
  }

  reinstiatePubList() {
    this.dropdownList = [];
    for (let x of this.arrOfPub) {
      let newData = {
        item_id: x.databaseId,
        item_text: x.publisherName
      }
      this.dropdownList.push(newData);
    }
  }

  async ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 90,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];

    this.listJob = await this.orderTrackingService.getJobTemplate();
    // this.listSubdomain = await this.orderTrackingService.getAllSubdomain();
    let pubData = await this.pubService.getAllPublisher();
    this.arrOfPub = pubData[0];
    this.reinstiatePubList();
    this.loggedIn = await this.singletonServices.getUserData();
    this.object.selectJob = this.listJob[0].id;

    this.firstJobExists = this.listJob[0].templateChild.find((e2) => {
      return e2.PIC == this.loggedIn.employeeDetail.group_id
    });
    try {
      let getDetailToFill = JSON.parse(this.firstJobExists.requestData);
      for (let data in getDetailToFill) {
        let createObject: any = {};
        createObject['value'] = '';
        createObject['title'] = data;
        createObject['type'] = getDetailToFill[data];
        this.detailToFill.push(createObject);
      }
    } catch (e) {
      console.log(e);
    }
  }

  changeJobAction(jobs) {
    this.detailToFill = [];
    jobs = this.listJob.find((e) => {
      return e.id == jobs
    })
    if (jobs.id == 2) {
      this.isAppsOnly = true;
    } else {
      this.isAppsOnly = false;
    }
    this.firstJobExists = jobs.templateChild.find((e2) => {
      return e2.PIC == this.loggedIn.employeeDetail.group_id
    });
    if (!this.firstJobExists) {
      this.detailToFill = [];
    }
    try {
      let getDetailToFill = JSON.parse(this.firstJobExists.requestData);
      for (let data in getDetailToFill) {
        let createObject: any = {};
        createObject['value'] = '';
        createObject['title'] = data;
        createObject['type'] = getDetailToFill[data];
        this.detailToFill.push(createObject);
      }
    } catch (e) {
      console.log(e);
    }
  }

  checkIsCanSelectPub() {
    if (!this.object.selectJob){
      return false;
    } else {
      let obj = this.listJob.find((e) => e.id == this.object.selectJob);
      if (obj.selectPub == 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  async insertAction() {
    let obj = this.listJob.find((e) => e.id == this.object.selectJob);
    let strfiedJson: any;
    if (this.firstJobExists) {
      if (this.detailToFill.length > 0) {
        for (let data of this.detailToFill) {
          if (data.type == "boolean") {
            if (data.value == "") {
              data.value = false;
            }
          } else if (data.type == "requiredstring") {
            if (data.value == "") {
              this.toastr.error(`Field ${data.title} wajib diisi!`, 'Gagal!', {
                timeOut: 3000
              });
              return;
            }
          } else if (data.type == "requiredLongString") {
            if (data.value == "") {
              this.toastr.error(`Field ${data.title} wajib diisi!`, 'Gagal!', {
                timeOut: 3000
              });
              return;
            }
          } else if (data.type == "requiredBoolean") {
            if (data.value == "" || data.value == false) {
              this.toastr.error(`Field ${data.title} harus dicentang!`, 'Gagal!', {
                timeOut: 3000
              });
              return;
            }
          }
        }
        strfiedJson = {
          jobs: this.firstJobExists,
          details: JSON.stringify(this.detailToFill)
        }
      }
    }
    else {
      strfiedJson = null;
    }
    if (obj.selectPub == 0) {
      this.orderTrackingService.createNewJob(this.object.selectJob, this.website_id, strfiedJson).then((res) => {
        this.toastr.success('Sukses menambah pekerjaan', 'Sukses', {
          timeOut:3000
        })
      })
    } else {
      if (this.website_id == -1) {
        this.toastr.error('Belum memilih website', 'Gagal menambah pekerjaan!', {
          timeOut: 3000
        });
        return;
      }
      this.orderTrackingService.createNewJob(this.object.selectJob, this.website_id, strfiedJson).then((res) => {
        this.toastr.success('Sukses menambah pekerjaan', 'Sukses', {
          timeOut:3000
        })
      })
    }

  }

  getDomainDetail(params) {
    let check = this.domainList.find((e) => {return e.website_id == params});
    if (check) {
      return check.publisherUrl;
    } else {
      return "";
    }
  }

  removeFromArray(params) {
    let check = this.website_id.find((e) => {return e == params});
    if (check) {
      let checkIndex = this.website_id.findIndex((e) => {return e == params});
      this.website_id.splice(checkIndex, 1);
      this.website_list = JSON.parse(JSON.stringify(this.website_id));
    }
    console.log("MANTEP");
  }

  async websiteIdSelected(params) {
    if (Array.isArray(params)) {
      this.website_id = params;
      for (let x of params) {
        // let check = this.website_list.find((e) => {return e == x});
        // if (!check) {
        //   this.website_list.push(x);
        // }
        this.website_list = this.website_id;
      }
      if (params.length == 0) {
        this.website_list = [];
      }
    } else {
      let check = this.website_list.find((e) => {return e == params});
      if (!check) {
        this.website_list.push(params);
      } else {
        let checkIndex = this.website_list.findIndex((e) => {return e == params});
        this.website_list.splice(checkIndex, 1);
      }
      this.website_id = this.website_list;
      this.website_list = JSON.parse(JSON.stringify(this.website_id));
    }
  }

  async refetchDomainlist() {
    this.website_list = [];
    // this.domainList = [];
    let temp = [];
    for (let data of this.selectedItems) {
      let findData = this.arrOfPub.find((e)=> {return e.databaseId == data.item_id});
      if (findData) {
        let res: any = await new Promise((resolve) => {
          this.pubService.getSpecificPublisher(findData.publisherIdentifier).then((res : any) => {
            temp.push(...res.domain_list);
            resolve(null);
          })
        })

      }
    }
    // console.log(this.domainList);
    this.domainList = temp;
  }
}
