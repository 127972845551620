import { Component, OnInit } from "@angular/core";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthorizationService } from "src/app/service/authorization.service";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { SingletonServices } from "src/app/service/singleton.service";
import { DialogJobComponent } from "../../dialog/dialog-job.component";
import { DialogParentComponent } from "../../dialog/dialog-parent/dialog-parent.component";

@Component({
  selector: "app-card-dashboard-job-history",
  templateUrl: "./card-dashboard-job-history.html"
})
export class CardDashboardJobHistory implements OnInit {
  trackingData: any = [];
  userData: any = [];
  constructor(public dialog: MatDialog, public orderTrackingService: OrderTrackingService,private singletonService: SingletonServices) {

  }

  async ngOnInit() {
    this.trackingData = await this.orderTrackingService.getHistoryJobAll();
    this.userData = await this.singletonService.getUserData();
  }

  jobSelected(ids) {
    let target = this.trackingData.find((e) => e.id == ids);
    this.detailJobs(target);
  }

  detailJobs(data) {
    const dialogRef = this.dialog.open(DialogParentComponent, {
      data: data,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getJobClass(param) {
    let status = 0;

    let lstJob = param.lstOfJob;
    let locked = [];
    let noPrereq = [];
    for (let data of lstJob) {

      if (!data.preRequisite) {
        if (data.status == 0) {
          noPrereq.push(data);
        }
        continue;
      }
      let arrPrereq = JSON.parse(data.preRequisite);
      for (let data2 of arrPrereq) {
        let findData = lstJob.find((e) => data2 == e.id);
        if (findData.status == 0) {
          let fd2 = locked.find((e) => e.id == findData.id);
          if (!fd2) {
            locked.push(findData);
          }
        }
      }
    }
    let unlocked = param.lstOfJob.filter((e) => {
      if (locked.filter(lock => lock.id == e.id).length > 0) {
        return true;
      } else {
        return false;
      }
      // return !locked.includes((lock)=>{return lock.id == e.id})
    })
    unlocked.push(...noPrereq);

    let setToGroup = unlocked.filter((e) => {
      return e.PIC  == 6
    })
    if (setToGroup.length > 0) {
      return 'jobPending'
    } else {
      return 'jobDone'
    }
  //   if (param.preRequisite) {
  //     let arrPrereq = JSON.parse(param.preRequisite);
  //     for (let data of arrPrereq) {
  //       let findData = this.progressDetail.listJob.find((e) => data == e.id);
  //       if (findData.status == 0) {
  //         return "job-detail-container locked";
  //       }
  //     }
  //   }

  //   status = param.status;
  //   if (status == 0) {
  //     return "job-detail-container uncompleted"
  //   } else if (status == 1) {
  //     return "job-detail-container completed";
  //   } else {
  //     return "job-detail-container locked";
  //   }

  }

}
