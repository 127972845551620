import { Component, OnInit } from "@angular/core";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "app-card-publisher",
  templateUrl: "./card-publisher.component.html",
  styleUrls: ["./card-publisher.css"]
})
export class CardPublisherComponent implements OnInit {
  constructor( private pubService: PublisherService, private singletonService: SingletonServices) {}
  selectedPublisher: string;

  numOfPublisher: any = 0;
  arrOfPub = [];

  pubDetail: any = {
    domain_list: [],
    isPremium: 0,
    joinDate: "",
    mcmEmail: "",
    picEmail: "",
    picName: "",
    publisherIdentifier: "",
    publisherName: "",
    status: 0
  }

  getWarningValue() {
    if (!this.singletonService) {
      return [];
    }
    if (!Array.isArray(this.singletonService.problemList)) {
      return [];
    }
    return this.singletonService.problemList;
  }


  getCompareValue() {
    return this.singletonService.compareData;
  }

  checkAdunitList() {
    if (!this.singletonService) {
      return true;
    }
    if (!this.singletonService.adUnitList) {
      return true;
    }
    return this.singletonService.adUnitList.length == 0 ? true : false;
  }

  listingAdunit() {
    let arr = [];

    if (!this.singletonService) {
      return arr;
    }

    for (let row of this.singletonService.adUnitList) {
      let obj = {
        adUnitName: row.adUnitList.alias + `(${row.adUnitList.type == 2 ? 'Desktop': 'Mobile'})`
      }
      arr.push(obj);
    }
    return arr;
  }

  // getSelectedPublisher() {
  //   return this.singletonService.selectedUrl;
  // }

  async ngOnInit() {
    let pubData = await this.pubService.getAllPublisher();
    this.arrOfPub = pubData[0];
    this.numOfPublisher = pubData[1];
  }

  async getPublisherDetail() {
    this.singletonService.compareData = null;
    this.singletonService.problemList = null;
    this.pubDetail = await this.pubService.getSpecificPublisher(this.selectedPublisher);
  }
}
