import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";
import * as moment from 'moment';

@Component({
  selector: "datatables-joblist",
  templateUrl: "./datatables-joblist.html",
})
export class DatatablesJoblistTracking implements OnInit {
    dtOptions: DataTables.Settings = {};
    dtTrigger: any = new Subject();

    @Input()
    public currentJobs : any;

    @Output()
    valueEmitter = new EventEmitter<any>();

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    lastElements: any;

    public valTable: any[];

    constructor(
      private publishService: PublisherService,
      private singletonService: SingletonServices
    ) {}

    ngOnInit(): void {
      this.valTable = [];
      // console.log(this.currentJobs)
      // for (let data of this.currentJobs) {
      //   let obj = {
      //     id: data.id,
      //     initiator: data.JobParent.initiator,
      //     jobName: data.TemplateChild.jobName,
      //     jobParent: data.JobTemplate.name,
      //     domain: data.JobParent.domName.publisherUrl
      //   }
      //   this.valTable.push(obj);
      // }

      this.dtOptions = {
          // pagingType: 'full_numbers',
          order: [[0, 'desc']],
          ajax: (dataTablesParameters: any, callback) => {
            callback({
                data: this.valTable,
                recordsTotal: this.valTable.length,
                recordsFiltered: this.valTable.length
            })
          },
          columns: [
          // {
          //   title: 'ID',
          //   data: 'id'
          // },
          // {
          //   title: 'Initiator',
          //   data: 'initiator'
          // },
          {
            title: 'Assigned Time',
            render: (data, type, row) => {
              let strTime = moment(row.assignedTime).format("DD MMM YYYY HH:mm");
              return strTime;
            },
            type: 'date'

          },{
            title: 'What to do',
            data: 'jobName'
          },{
            title: 'To complete',
            data: 'jobParent'
          },{
            title: 'Domain',
            data: 'domain'
          },{
            title: 'Publisher',
            data: 'publisherName'
          }
        ],
          rowCallback: (row: Node, data: any, index: number) => {
            const self = this;
            $('td', row).off('click');
            $('td', row).on('click', async (target) => {
              this.valueEmitter.emit(data.id);
            });
            return row;
          }
      };
    }

    ngAfterViewInit(): void {
    this.dtTrigger.next();
    }

    ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }

    ngOnChanges(chg) {
        this.currentJobs = chg.currentJobs.currentValue;
        this.valTable = [];
        for (let data of this.currentJobs) {
          let domainList = "";
          let pubList = "";
          let idx = 0;
          for (let domList of data.JobParent.domName) {
            if (idx++ < 5) {
              domainList += `${domList.publisherUrl} `;
            }
            if (idx == 5) {
              domainList = domainList + "<b>and many more</b>"
            }
            if (!pubList.includes(domList.pubDetail.publisherName)) {
              pubList += `${domList.pubDetail.publisherName} `;
            }

          }
          let obj = {
            id: data.id,
            initiator: data.JobParent.initiator,
            jobName: data.TemplateChild.jobName,
            jobParent: data.JobTemplate.name,
            domain: domainList,
            publisherName: pubList,
            assignedTime: data.assignedTime
          }
          this.valTable.push(obj);
        }
        this.rerender();
    }

    rerender(): void {
      if (this.dtElement){
        if (this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });
        }
      }
    }
}
