import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";

@Component({
  selector: "script-maker-base",
  templateUrl: "./script-maker-base.html",
  styleUrls: ['script-maker-base.css']
})

export class ScriptMakerBase implements OnInit {
  adUnitName: string;
  fileName: string;
  regexFinder: string = "160553881,22788822153";
  pubmaticChange: string = "true";
  pubmaticValue: string = "";
  domChg: string = "true"
  pudomainChangeValue: string = "";

  constructor(
    public orderTrackingService: OrderTrackingService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {

  }

  insertAction() : void {
  }

  async generateJS() {
    let blob = await this.orderTrackingService.generatePTIScript(this.adUnitName, this.fileName, this.regexFinder, this.pubmaticChange, this.pubmaticValue, this.domChg, this.pudomainChangeValue);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = "generated_script.zip";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

}
