import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "datatables-subdomainlist",
  templateUrl: "./datatables-subdomainlist.html",
})
export class DatatablesSubdomainlistComponent implements OnInit {
    dtOptions: DataTables.Settings = {};
    dtTrigger: any = new Subject();

    @Input()
    public lstDomain : any;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    constructor(
      private publishService: PublisherService,
      private trackingService: OrderTrackingService,
      private singletonService: SingletonServices
    ) {}

    ngOnInit(): void {

    this.dtOptions = {
        pagingType: 'full_numbers',
        destroy:true,
        ajax: (dataTablesParameters: any, callback) => {
          callback({
              data: this.lstDomain,
              recordsTotal: this.lstDomain.length,
              recordsFiltered: this.lstDomain.length
          })
        },
        columns: [{
          title: 'Domain Url',
          data: 'publisherUrl'
        }],
        rowCallback: (row: Node, data: any, index: number) => {
          const self = this;

          $('td', row).off('click');
          $('td', row).on('click', async () => {
            let gdt: any = await this.publishService.getDomainPerformance(data.website_id);
            this.singletonService.data1 = gdt.data1;
            this.singletonService.data2 = gdt.data2;
            this.singletonService.compareData = gdt.compareData;

            this.singletonService.selectedUrl = data.publisherUrl;

            let adUnitList: any = await this.trackingService.getDomainAdSlotList(data.publisherUrl)
            this.singletonService.adUnitList = adUnitList;

            let wrl: any = await this.publishService.getAdsDomainWarning(data.website_id);
            this.singletonService.problemList = [];
            for (let data of wrl) {
              if (data.isProblem) {
                this.singletonService.problemList.push(data);
              }
            }
          });
          return row;
        }
    };
    }

    ngAfterViewInit(): void {
    this.dtTrigger.next();
    }

    fetchDomainInformation(dt) {
    }

    ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }

    ngOnChanges(chg) {
        this.lstDomain = chg.lstDomain.currentValue;
        this.rerender();
    }

    rerender(): void {
      if (this.dtElement){
        if (this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });
        }
      }
    }
}
