import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "tracking-subdomainlist",
  templateUrl: "./tracking-subdomainlist.html",
  styleUrls: ['tracking-subdomainlist.scss']
})
export class TrackingSubdomainlistComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();

  @Input()
  public lstDomain : any;

  @Input()
  public selectedData : any;

  @Output()
  valueEmitter = new EventEmitter<any>();

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  lastElements: any;

  constructor(
    private publishService: PublisherService,
    private singletonService: SingletonServices
  ) {}

  ngOnInit(): void {
    this.dtOptions = {
        pagingType: 'full_numbers',
        ajax: (dataTablesParameters: any, callback) => {
          callback({
              data: this.lstDomain,
              recordsTotal: this.lstDomain.length,
              recordsFiltered: this.lstDomain.length
          })
        },
        columns: [{
          title: 'Domain Url',
          data: 'publisherUrl'
        }],
        rowCallback: (row: Node, data: any, index: number) => {
          const self = this;
          $('td', row).off('click');
          $('td', row).on('click', async (target) => {
            this.valueEmitter.emit(data.website_id);
            if ($(target.target).css("backgroundColor") == "rgb(128, 128, 128)") {
              $(target.target).css("backgroundColor", "white")
            } else {
              target.target.style.backgroundColor = "gray";
            }

          });
          return row;
        },
        drawCallback: (settings) => {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.rows().every((idx, rowIdx, tableLoop) => {
              // $(dtInstance.row(idx).node()).css("backgroundColor", "gray")
              let website_id = dtInstance.row(idx).data()['website_id'];
              let findData = this.selectedData.find((e) => {return e == website_id});
              if (findData) {
                $(dtInstance.row(idx).node()).css("backgroundColor", "gray")
              } else {
                $(dtInstance.row(idx).node()).css("backgroundColor", "white")
              }
            });
          });
        }
    };
  }

  selectAllDoms() : void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.rows().every((idx, rowIdx, tableLoop) => {
        $(dtInstance.row(idx).node()).css("backgroundColor", "gray")
      });
      let newStructure = [];
      for (let x of this.lstDomain) {
        newStructure.push(x.website_id);
      }
      this.valueEmitter.emit(newStructure);
    });
  }

  deselectAllDoms() : void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.rows().every((idx, rowIdx, tableLoop) => {
        $(dtInstance.row(idx).node()).css("backgroundColor", "white")
      });

      this.valueEmitter.emit([]);
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
  // Do not forget to unsubscribe the event
  this.dtTrigger.unsubscribe();
  }

  ngOnChanges(chg) {
    // console.log(this.lstDomain);
    // this.lstDomain = chg.lstDomain.currentValue;
    // console.log(this.lstDomain);
    // console.log(chg.lstDomain);
    this.rerender();
  }

  rerender(): void {
    if (this.dtElement){
      if (this.dtElement.dtInstance) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();

          dtInstance.rows().every((idx, rowIdx, tableLoop) => {
            $(dtInstance.row(idx).node()).css("backgroundColor", "gray")
          });

        });
      }
    }

    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //     // Destroy the table first
    //     dtInstance.destroy();
    //     // Call the dtTrigger to rerender again
    //     this.dtTrigger.next();

    //     dtInstance.rows().every((idx, rowIdx, tableLoop) => {
    //       $(dtInstance.row(idx).node()).css("backgroundColor", "gray")
    //     });

    // });
  }
}
