import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";
import { AuthComponent } from "./layouts/auth/auth.component";

// admin views
import { DashboardComponent } from "./views/admin/dashboard/dashboard.component";
import { SettingsComponent } from "./views/admin/settings/settings.component";
import { TablesComponent } from "./views/admin/tables/tables.component";

// auth views
import { LoginComponent } from "./views/auth/login/login.component";
import { RegisterComponent } from "./views/auth/register/register.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";
import { LandingComponent } from "./views/landing/landing.component";
import { ProfileComponent } from "./views/profile/profile.component";

// components for views and layouts

import { CardPublisherComponent } from "./components/cards/card-profile/card-publisher.component";
import { FooterAdminComponent } from "./components/footers/footer-admin/footer-admin.component";
import { FooterComponent } from "./components/footers/footer/footer.component";
import { FooterSmallComponent } from "./components/footers/footer-small/footer-small.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { AdminNavbarDashboardComponent } from './components/navbars/admin-navbar-dashboard/admin-navbar-dashboard.component';
import { HeaderNewOrderComponent } from './components/headers/header-new-order/header-new-order.component';
import { CardNewOrderComponent } from './components/cards/card-new-order/card-new-order.component';
import { CardOrderDetailComponent } from './components/cards/card-order-detail/card-order-detail.component';
import { CardNewOrderBulkComponent } from './components/cards/card-new-order-bulk/card-new-order-bulk.component';
import { CardDashboardMainComponent } from "./components/cards/card-dashboard-main/card-dashboard-main.component";
import { CardDashboardWarningComponent } from "./components/cards/card-dashboard-warning/card-dashboard-warning.component";
import { DialogJobComponent } from "./components/dialog/dialog-job.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from "@angular/material/dialog";
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import { DataTablesModule } from "angular-datatables";
import { HttpClientModule } from '@angular/common/http';
import { HttpSetup } from "./service/httpSetup.service";
import { OrderTrackingService } from "./service/orderTracking.service";
import { FormsModule } from "@angular/forms";
import { CashDashboardParentComponent } from "./components/cards/card-dashboard-parent/card-dashboard-parent.component";
import { PublisherService } from "./service/publisher.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { DatatablesSubdomainlistComponent } from "./components/publisher-datatables/subdomain-list/datatables-subdomainlist.component";
import { AuthorizationService } from "./service/authorization.service";
import { DialogParentComponent } from "./components/dialog/dialog-parent/dialog-parent.component";
import { SingletonServices } from "./service/singleton.service";
import { TrackingSubdomainlistComponent } from "./components/tracking-datatables/subdomain-list/tracking-subdomainlist.component";
import { DatatablesJoblistTracking } from "./components/tracking-datatables/datatables-joblist/datatables-joblist-tracking";
import { DatatablesJoblistAllTracking } from "./components/tracking-datatables/datatables-joblist-all/datatables-joblist-tracking-all";
import { ToastrModule } from "ngx-toastr";
import { CardDashboardJobHistory } from "./components/cards/card-dashboard-job-history/card-dashboard-job-history.component";
import { CardAdslotModules } from "./components/cards/card-adslot-modules/card-adslot-module";
import { DynamicConfirmationDialog } from "./components/confirmation-dialog/dynamic-confirmation";
import { CodeEditorModule } from "@ngstack/code-editor";
import { SetJavascriptBase } from "./components/cards/card-set-javascript-base/set-javascript-base";
import { ScriptMakerBase } from "./components/cards/card-script-maker-input/script-maker-base";
import { ScriptGeneratorComponent } from "./views/admin/scriptgen/scriptgen.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { MatExpansionModule } from "@angular/material/expansion";
import { DatatablesAdunitList } from "./components/publisher-datatables/adunit-list/adunit_list.component";
import { AdunitSearchComponent } from "./views/admin/adunit-search-component/adunitsearch";

@NgModule({
  declarations: [
    AppComponent,
    AdminNavbarDashboardComponent,
    CardNewOrderComponent,
    CardDashboardMainComponent,
    CardDashboardWarningComponent,
    DialogJobComponent,
    DialogParentComponent,
    HeaderNewOrderComponent,
    DashboardComponent,
    SidebarComponent,
    FooterComponent,
    FooterSmallComponent,
    FooterAdminComponent,
    CardOrderDetailComponent,
    CardNewOrderBulkComponent,
    CardPublisherComponent,
    AdminComponent,
    AuthComponent,
    SettingsComponent,
    TablesComponent,
    LoginComponent,
    RegisterComponent,
    IndexComponent,
    LandingComponent,
    ProfileComponent,
    CashDashboardParentComponent,
    DatatablesSubdomainlistComponent,
    TrackingSubdomainlistComponent,
    DatatablesJoblistTracking,
    DatatablesJoblistAllTracking,
    CardDashboardJobHistory,
    CardAdslotModules,
    DynamicConfirmationDialog,
    SetJavascriptBase,
    ScriptMakerBase,
    ScriptGeneratorComponent,
    DatatablesAdunitList,
    AdunitSearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule,
    NgSelectModule,
    MatTabsModule,
    MatIconModule,
    MatInputModule,
    MatExpansionModule,
    MatRadioModule,
    ToastrModule.forRoot(), // ToastrModule added
    CodeEditorModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [HttpSetup, OrderTrackingService, PublisherService, AuthorizationService, SingletonServices],
  bootstrap: [AppComponent],
})
export class AppModule {}
