import { Component, OnInit } from "@angular/core";
import { AuthorizationService } from "src/app/service/authorization.service";
import { HttpSetup } from "src/app/service/httpSetup.service";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
})
export class SettingsComponent implements OnInit {
  constructor(public httpSetup: HttpSetup, public authService: AuthorizationService) {
    let authCode = localStorage.getItem('authcode');
    this.httpSetup.setAuthorization(authCode);
    this.authService.userInfo().then((res) => {
    })
  }

  ngOnInit(): void {}
}
