import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpSetup } from "./httpSetup.service";


@Injectable()
export class PublisherService {
    constructor(
        private httpClient: HttpClient,
        private httpSetup: HttpSetup
    ) {

    }

    async getAdsDomainWarning(domainId) {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrlAutomation('publisher/getAdsDomainWarning'), {headers: this.httpSetup.setHeaders(), params: {
                domain_id: domainId
            }}).subscribe(data => {
                resolve(data);
            })
        });
    }

    async searchByAdUnits(objects){
      return new Promise((resolve) => {
        this.httpClient.post<any>(this.httpSetup.getUrl('publisher/searchByAdUnits'), objects, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
          resolve(data);
        })
      })
    }

    async getSpecificPublisher(publisherIdentifier) {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('publisher/getSpecificPublisher'), {headers: this.httpSetup.setHeaders(), params: {
                publisherIdentifier: publisherIdentifier
            }}).subscribe(data => {
                resolve(data);
            })
        });
    }

    async getDomainPerformance(domain_id) {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrlAutomation('publisher/getAdsDomainPerformance'), {headers: this.httpSetup.setHeaders(), params: {
                domain_id: domain_id
            }}).subscribe(data => {
                resolve(data);
            });
        })
    }

    async getAllPublisher() {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('publisher/getAllPublisher'), {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            })
        });
    }

    async createNewPublisher(publisherIdentifier: any, publisherName: any, status: any, isPremium: any,mcmEmail: any, picEmail: any, picName: any, publisherArray: any) {
        return new Promise((resolve) => {
            this.httpClient.post<any>(this.httpSetup.getUrl('publisher/createNewPublisher'), {
                publisherIdentifier: publisherIdentifier,
                publisherName: publisherName,
                status: status,
                isPremium: isPremium,
                mcmEmail: mcmEmail,
                picEmail: picEmail,
                picName: picName,
                pubArr: publisherArray
            }, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            })
        })
    }
}
