import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "datatables-adunitlist",
  templateUrl: "./adunit_list.html",
})
export class DatatablesAdunitList implements OnInit {
    dtOptions: DataTables.Settings = {};
    dtTrigger: any = new Subject();

    @Input()
    public lstAdunit : any;

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    constructor(
      private publishService: PublisherService,
      private trackingService: OrderTrackingService,
      private singletonService: SingletonServices
    ) {}

    ngOnInit(): void {

    this.dtOptions = {
        pagingType: 'full_numbers',
        destroy:true,
        ajax: (dataTablesParameters: any, callback) => {
          callback({
              data: this.lstAdunit,
              recordsTotal: this.lstAdunit.length,
              recordsFiltered: this.lstAdunit.length
          })
        },
        columns: [{
          title: 'Ad Unit Name',
          data: 'adUnitName'
        }]
    };
    }

    ngAfterViewInit(): void {
    this.dtTrigger.next();
    }

    ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }

    arrayEquals(a, b) {
      if (Array.isArray(a) && Array.isArray(b)) {
        let exists = false;
        for (let rowA of a) {
          for (let rowB of b) {
            if (rowA.adUnitName == rowB.adUnitName) {
              exists = true;
            }
          }
        }
        if (exists == false) {
          return false;
        } else {
          return true;
        }
      }
      return false;
  }

    rerender(): void {
      if (this.dtElement) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          // Destroy the table first
          dtInstance.destroy();
          // Call the dtTrigger to rerender again
          this.dtTrigger.next();
        });
      }

    }
}
