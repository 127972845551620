import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";

export interface CodeModel {
  language: string;
  value: string;
  uri: string;

  dependencies?: Array<string>;
  schemas?: Array<{
    uri: string;
    schema: Object;
  }>;
}

@Component({
  selector: "set-base-javascript",
  templateUrl: "./set-base-javascript.html",
})

export class SetJavascriptBase implements OnInit {
    theme = 'vs-dark';
    ptiScriptVal: any = "";
    codeModel = {
      language: 'javasript',
      value: 'asd'
    };

    options = {
      contextmenu: true,
      minimap: {
        enabled: true
      }
    };

    onCodeChanged(value) {
      this.ptiScriptVal = value;
    }

    constructor(
      public orderTrackingService: OrderTrackingService,
      private toastr: ToastrService
    ) {}

    async ngOnInit() {
      this.ptiScriptVal = await this.orderTrackingService.getPTIScript();
      // this.codeModel.value = this.ptiScriptVal;
      this.codeModel = {
        language: 'javascript',
        value: this.ptiScriptVal
      }
    }

    ngAfterViewInit(): void {
    }

    ngOnDestroy(): void {

    }

    insertAction() : void {
      this.orderTrackingService.setPTIScript(this.ptiScriptVal).then((res) => {
        this.toastr.success("Success Update Script", "Update Status", {
          timeOut:3000
        })
        console.log(res);
      })
    }

}
