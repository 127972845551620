import { Component, Input, OnInit } from "@angular/core";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { DialogJobComponent } from "../../dialog/dialog-job.component";

@Component({
  selector: "app-card-dashboard-main",
  templateUrl: "./card-dashboard-main.html",
})
export class CardDashboardMainComponent implements OnInit {
  trackingData: any = [];
  trackingDataPending: any = [];
  @Input()
  public currentIds : any;
  public isMoreThan1Week: any = false;
  constructor(public dialog: MatDialog, public orderTrackingService: OrderTrackingService,  private toastr: ToastrService) {

  }

  async ngOnInit() {
    this.trackingData = await this.orderTrackingService.getCurrentJob(!this.isMoreThan1Week);
    this.trackingDataPending = await this.orderTrackingService.getCurrentJob(this.isMoreThan1Week);
    if (this.currentIds) {
      let datas = await this.orderTrackingService.getSingleJob(this.currentIds);
      this.detailJobs(datas[0]);
    }
  }

  detailJobs(data) {
    const dialogRef = this.dialog.open(DialogJobComponent, {
      maxHeight: '85vh !important',
      data: data,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  jobSelected(event) {
    let data = this.trackingData.find((e) => {
      return e.id == event
    });
    if (!data) {
      data = this.trackingDataPending.find((e) => {
        return e.id == event
      })
    }
    this.detailJobs(data);
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogJobComponent, {
      maxHeight: '65vh !important',
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
