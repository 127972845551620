import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { AuthorizationService } from "./authorization.service";
import { HttpSetup } from "./httpSetup.service";


@Injectable()
export class SingletonServices {
    public data1: any;
    public data2: any;
    public compareData: any;
    public selectedUrl: any;
    public problemList: any = [];
    public adUnitList: any = [];
    public userData: any = null;

    // public checkComparedataChanged: Subject<any> = new Subject<any>();
    constructor(
        private authService: AuthorizationService
    ) {
    }

    async getUserData() {
        if (this.userData == null) {
            this.userData = await this.authService.userInfo();
        }
        return this.userData;
    }

    async getDomainResult() {
        return {
            data1: this.data1,
            data2: this.data2,
            resultData: this.compareData
        };
    }
}
