import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { PublisherService } from "src/app/service/publisher.service";
import {MatAccordion} from '@angular/material/expansion';

@Component({
  selector: "card-adslot-module",
  templateUrl: "./card-adslot-module.html",
  styleUrls: ['card-adslot-module.scss']
})
export class CardAdslotModules implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;

  lstOfadslot: any = [];
  isFetching = false;
  lstOfChecked: any = [];
  newObjOfAdslot: any = [];

  @Input()
  appsOnly = false;

  @Output()
  adslotValue = new EventEmitter<any>();

  constructor(
    private orderTrackingService: OrderTrackingService
  ) {}

  isExistInSelected(id) {
    let find = this.lstOfChecked.find((e) => {return e.id == id});
    if (find){
      return true;
    }
    return false;
  }

  changeType(event, id) {
    let find = this.lstOfChecked.find((e) => {return e.id == id});

    if (event.target.value == 'Add') {
      find.isAdd = true;
    } else {
      find.isAdd = false;
    }

    this.adslotValue.emit(this.lstOfChecked);
  }

  listenTextChange(event, id) {
    let find = this.lstOfChecked.find((e) => {return e.id == id});
    find.note = event.target.value;


    this.adslotValue.emit(this.lstOfChecked);
  }

  listenNewChange(event, data) {
    if (!event.target.checked) {
      data.checked = false;
    } else {
      data.checked = true;
    }
  }

  listenChangeNewUi(id, adSlotName, platform) {
    let getIdx = this.lstOfChecked.findIndex((e) => {return e.id == id});
    if (getIdx == -1) {
      let checkExist = this.lstOfChecked.find((e) => {return e.id == id});
      if (!checkExist) {
        this.lstOfChecked.push({
          id: id,
          adSlotName: adSlotName,
          isAdd: true,
          note: '',
          platform: platform
        })
      }
    } else {
      this.lstOfChecked.splice(getIdx,1);
    }
    this.adslotValue.emit(this.lstOfChecked);
  }

  listenChange(event, id, adSlotName, platform) {
    if (event.target.checked) {
      this.lstOfChecked.push({
        id: id,
        adSlotName: adSlotName,
        isAdd: true,
        note: '',
        platform: platform
      })
    } else {
      let getIdx = this.lstOfChecked.findIndex((e) => {return e.id == id});
      this.lstOfChecked.splice(getIdx,1);
    }
    this.adslotValue.emit(this.lstOfChecked);
  }

  getDesktopAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 2});
    return filteredData;
  }

  getMobileAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 1});
    return filteredData;
  }

  getOtherAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 3});
    return filteredData;
  }

  checkHasPlatform(data, idPlatform) {
    let hasPlatform = false;
    for (let platform of data.platform) {
      if (platform.type == idPlatform) {
        hasPlatform = true;
      }
    }

    return hasPlatform;
  }

  getPlatformObject(data, idPlatform) {
    let id = data.platform.find((e) => e.type == idPlatform);
    return id;
  }

  reconstructAdslotModules(){
    let arrOfData = [];

    for (let data of this.lstOfadslot) {
      let check = arrOfData.find((e) => {return e.adSlotAlias == data.alias});
      if (!check) {
        arrOfData.push({
          adSlotAlias: data.alias,
          platform: [],
          checked: false,
          classifier: data.classifier
        });
      }
      check = arrOfData.find((e) => {return e.adSlotAlias == data.alias});
      check.platform.push(data);
    }

    let arrOfClassified = [];
    for (let x of arrOfData) {
      let check = arrOfClassified.find((e) => {return e.classifier == x.classifier});
      if (!check) {
        arrOfClassified.push({
          classifier: x.classifier,
          adSlot: [x]
        });
      } else {
        check.adSlot.push(x);
      }
    }

    if (this.appsOnly) {
      arrOfClassified = arrOfClassified.filter((e) =>{return e.classifier == "Apps"})
    } else {
      arrOfClassified = arrOfClassified.filter((e) =>{return e.classifier != "Apps"})
    }
    this.newObjOfAdslot = arrOfClassified;

    // this.newObjOfAdslot.sort((a,b) => {return (a.adSlotAlias > b.adSlotAlias) ? 1 : (a.adSlotAlias < b.adSlotAlias) ? -1 : 0});

    for (let x of this.newObjOfAdslot) {
      x.adSlot.sort((a,b) => {return (a.adSlotAlias > b.adSlotAlias) ? 1 : (a.adSlotAlias < b.adSlotAlias) ? -1 : 0});
    }
  }

  async ngOnInit() {
    this.isFetching = true;
    this.orderTrackingService.getAdUnitList().then((res) => {
      this.lstOfadslot = res;
      this.isFetching = false;
      this.reconstructAdslotModules();
    })
  }
}
