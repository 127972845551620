import { Component, OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { ToastrService } from "ngx-toastr";
import { AuthorizationService } from "src/app/service/authorization.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  userName: any = '';
  password: any = '';
  constructor(private router: Router, private authService: AuthorizationService, private toastr: ToastrService) {

  }

  onPressEnter(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.loginPage();
    }
  }

  ngOnInit(): void {
    // this.goToDashPage();
    let authCode = localStorage.getItem('authcode');
    if (authCode) {
      this.router.navigate(['/admin'])
    }
  }

  loginPage() {
    // this.router.navigate(['/admin'])
    this.authService.login(this.userName, this.password).then((res : any) => {
      if (res.status == 'success') {
        localStorage.setItem('authcode', res.authCode);
        location.reload();
      } else {
        this.toastr.error('Username / Password is wrong!', 'Login Failed!', {
          timeOut: 3000
        });
      }
    }).catch((e) => {
      console.log(e);
        this.toastr.error(e.error ? e.error.message : 'Cannot connect to server', 'Login Failed!', {
          timeOut: 3000
        });
    });
  }
}
