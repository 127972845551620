import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { DataTableDirective } from "angular-datatables";
import { Subject } from "rxjs";
import { AuthorizationService } from "src/app/service/authorization.service";
import { PublisherService } from "src/app/service/publisher.service";
import { SingletonServices } from "src/app/service/singleton.service";
import * as moment from 'moment';

@Component({
  selector: "datatables-joblist-all",
  templateUrl: "./datatables-joblist-all.html",
})
export class DatatablesJoblistAllTracking implements OnInit {
    dtOptions: DataTables.Settings = {};
    dtTrigger: any = new Subject();

    @Input()
    public currentJobs : any;

    @Input()
    public userId: any;

    @Output()
    valueEmitter = new EventEmitter<any>();

    @ViewChild(DataTableDirective, {static: false})
    dtElement: DataTableDirective;

    lastElements: any;

    public valTable2: any[];

    public isAuthYet = false;

    constructor(
      private publishService: PublisherService,
      private singletonService: SingletonServices,
      private authService: AuthorizationService
    ) {}

    async ngOnInit() {
      this.valTable2 = [];

      this.dtOptions = {
          // pagingType: 'full_numbers',
          order: [[0, 'desc']],
          ajax: (dataTablesParameters: any, callback) => {
            callback({
                data: this.valTable2,
                recordsTotal: this.valTable2.length,
                recordsFiltered: this.valTable2.length
            })
          },
          columns: [
          {
            title: 'Started At',
            data: 'jobStartDate',
            type: 'date'
          },
          {
            title: 'Initiator',
            data: 'initiator'
          },
          {
            title: 'Job Name',
            data: 'jobName'
          },
          {
            title: 'Publisher',
            data: 'publisher'
          },
          {
            title: 'Domain',
            data: 'domain'
          },
          {
            title: 'Pending In',
            data: 'pendingIn'
          }
        ],
          rowCallback: (row: Node, data: any, index: number) => {
            const self = this;
            $('td', row).off('click');
            $('td', row).on('click', async (target) => {
              this.valueEmitter.emit(data.id);
            });
            return row;
          }
      };
    }

    ngAfterViewInit(): void {
    this.dtTrigger.next();
    }

    ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }

    async ngOnChanges(chg) {
      if (chg.currentJobs) {
        this.currentJobs = chg.currentJobs.currentValue;
        this.valTable2 = [];
        let pendingIn = [];

        for (let data of this.currentJobs) {
          let domainList = "";
          let pubList = "";
          let idx = 0;
          for (let domList of data.domName) {
            if (idx < 5) {
              idx++;
              domainList += `${domList.publisherUrl} `;
              if (idx == 5) {
                domainList += `<b>and many more</b>`;
              }
            }

            pubList = `${domList.pubDetail.publisherName}`
          }
          let obj = {
            id: data.id,
            initiator: data.initiator,
            jobName: data.JobTemplate.name,
            domain: domainList,
            pendingIn: data.pendingIn,
            publisher: pubList,
            jobStartDate: moment(data.jobStartedDate).utcOffset('+0700').format("llll")
          }
          this.valTable2.push(obj);
        }
        this.rerender();
      }
    }

    rerender(): void {
      if (this.dtElement){
        if (this.dtElement.dtInstance) {
          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
          });
        }
      }
    }
}
