import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpSetup } from "./httpSetup.service";
import { ToastrService } from "ngx-toastr";
import { StringDecoder } from "string_decoder";

@Injectable()
export class OrderTrackingService {
    constructor(
        private httpClient: HttpClient,
        private httpSetup: HttpSetup,
        private toastr: ToastrService
    ) {

    }

    async generatePTIScript(adUnitName, fileName, regexFinder, changePubmatic, pubmaticStrList, domChg, pudomainChangeValue) {
      let file = this.httpClient.post<Blob>(this.httpSetup.getUrl('scriptGen/createPTIScripts'), {adUnitName: adUnitName, fileName: fileName, regexFinder: regexFinder, changePubmatic: changePubmatic, pubmaticStrList: pubmaticStrList,changeAdDomain:domChg, changeAdDomainLst: pudomainChangeValue}, {responseType: 'blob' as 'json', headers: {"Content-Type": "application/json; charset=utf-8"}})
        .toPromise();

      return file;
    }

    async getDomainAdSlotList(publisherUrl) {
      return new Promise((resolve) => {
        this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getDomainAdUnits'), {headers: this.httpSetup.setHeaders(), params:{domainName: publisherUrl}}).subscribe(data => {
            resolve(data);
        }, error => {
          this.toastr.error('Error has occured, please try again later', 'Failed to edit current job', {
            timeOut: 3000
          });
        })
      })
    }

    async editCurrentJob(jobId, strfiedJson) {
      return new Promise((resolve) => {
        this.httpClient.post<any>(this.httpSetup.getUrl('tracking/editCurrentJob'), {jobId: jobId, strfiedJson: strfiedJson}, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
            resolve(data);
        }, error => {
          this.toastr.error('Error has occured, please try again later', 'Failed to edit current job', {
            timeOut: 3000
          });
        })
      })
    }

    async getAdUnitList() {

        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getAdUnitList'), {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to get ad unit list', {
                timeOut: 3000
              });
            })
        })
    }

    async getPTIScript() {
      return new Promise((resolve) => {
        this.httpClient.get<any>(this.httpSetup.getUrl('getPTIScript'), {headers: {"Content-Type": "text/plain; charset=utf-8"}, responseType: 'text' as 'json'}).subscribe(data => {
          resolve(data);
        }, error => {
          this.toastr.error('Error has occured, please try again later', 'Failed to get script', {
            timeOut: 3000
          });
        })
      })
    }

    async createNewJob(templateId, domainId, jobDetails = null) {
        return new Promise((resolve) => {
            this.httpClient.post<any>(this.httpSetup.getUrl('tracking/newJob'), {templateId: templateId, domainId: JSON.stringify(domainId), jobDetails: jobDetails}, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to create new job', {
                timeOut: 3000
              });
            })
        })
    }

    async setPTIScript(contentVal) {
      return new Promise((resolve) => {
          this.httpClient.post<any>(this.httpSetup.getUrl('setPTIScript'), {content: contentVal}, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
              resolve(data);
          }, error => {
            this.toastr.error('Error has occured, please try again later', 'Failed to save PTI Script', {
              timeOut: 3000
            });
          })
      })
  }

    async completeJob(id, strfiedJson) {
        return new Promise((resolve) => {
            this.httpClient.post<any>(this.httpSetup.getUrl('tracking/completeJob'), {id:id , strfiedJson: strfiedJson}, {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to edit complete current job', {
                timeOut: 3000
              });
            })
        })
    }

    async getJobTemplate() {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getJobTemplate'), {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to get job template', {
                timeOut: 3000
              });
            })
        })

    }

    async deleteCurrentJob(id) {
      return new Promise((resolve) => {
        this.httpClient.get<any>(this.httpSetup.getUrl('tracking/deleteCurrentJob'), {headers: this.httpSetup.setHeaders(), params: { id: id }}).subscribe(data => {
            resolve(data);
        }, error => {
          this.toastr.error('Error has occured, please try again later', 'Failed to edit delete current job', {
            timeOut: 3000
          });
        })
    })
    }

    async getAllSubdomain() {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getAllSubdomain'), {headers: this.httpSetup.setHeaders()}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to get all subdomain', {
                timeOut: 3000
              });
            })
        })

    }

    async getJobFromParent(parentId) {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getJobFromParent'), {headers: this.httpSetup.setHeaders(), params: { parentId: parentId }}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to get current job', {
                timeOut: 3000
              });
            })
        })

    }

    async getCurrentJob(isMoreThan1Week) {
        return new Promise((resolve) => {
            this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getCurrentJob'), {headers: this.httpSetup.setHeaders(), params: {isWeekly: isMoreThan1Week}}).subscribe(data => {
                resolve(data);
            }, error => {
              this.toastr.error('Error has occured, please try again later', 'Failed to get current job', {
                timeOut: 3000
              });
            })
        })
    }

    async getSingleJob(id) {
      return new Promise((resolve) => {
          this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getSingleJob'), {headers: this.httpSetup.setHeaders(), params: {id:id}}).subscribe(data => {
              resolve(data);
          }, error => {
            this.toastr.error('Error has occured, please try again later', 'Failed to get selected job', {
              timeOut: 3000
            });
          })
      })
  }

    async getHistoryJobAll() {
      return new Promise((resolve) => {
        this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getHistoryJobAll'), {headers: this.httpSetup.setHeaders()}).subscribe(data => {
            resolve(data);
        }, error => {
          this.toastr.error('Error has occured, please try again later', 'Failed to get history job', {
            timeOut: 3000
          });
        })
      })
    }

    async getCurrentJobAll(isMoreThan1Week) {
      return new Promise((resolve) => {
          this.httpClient.get<any>(this.httpSetup.getUrl('tracking/getCurrentJobAll'), {headers: this.httpSetup.setHeaders(), params: {isWeekly: isMoreThan1Week}}).subscribe(data => {
              resolve(data);
          }, error => {
            this.toastr.error('Error has occured, please try again later', 'Failed to get all current job', {
              timeOut: 3000
            });
          })
      })
    }
}
