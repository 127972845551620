import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogJobComponent } from "src/app/components/dialog/dialog-job.component";
import { AuthorizationService } from "src/app/service/authorization.service";
import { HttpSetup } from "src/app/service/httpSetup.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class DashboardComponent implements OnInit {
  idUser: any;

  constructor(public dialog: MatDialog, public httpSetup: HttpSetup, public authService: AuthorizationService, public router: Router, public actRoute: ActivatedRoute, private toastr: ToastrService) {
    let authCode = localStorage.getItem('authcode');
    if (authCode) {
      this.httpSetup.setAuthorization(authCode);
      this.authService.userInfo().then((res) => {
      })

      this.actRoute.paramMap.subscribe((params: ParamMap) => {
        let checkIdIsExist = params.has("id");
        if (checkIdIsExist) {
          this.idUser = params.get("id");
        }
      })

    } else {
      this.toastr.error('Login is expired!', 'Please Relogin!', {
        timeOut: 3000
      });
      this.router.navigate(['/auth/login'])
    }
  }

  ngOnInit() {}
}
