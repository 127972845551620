import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";


@Injectable()
export class HttpSetup {
    public baseUrl: string = 'https://apitracking.props.id/';
    // public baseUrl: string = 'http://localhost:5112/';
    public baseUrl2: string = 'https://mailinternal.props.id/';
    public authorization: string = '';
    public userData = {
        name: '',
        group: -1,
        groupStr: ''
    }

    setAuthorization(auth) {
        this.authorization = auth;
    }

    getUrl(endpoint) {
        return this.baseUrl + endpoint;
    }

    getUrlAutomation(endpoint) {
        return this.baseUrl2 + endpoint;
    }

    setHeaders(): any {
        return new HttpHeaders({
            'Authorization': this.authorization
        })
    }
}
