import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { DynamicConfirmationDialog } from "../../confirmation-dialog/dynamic-confirmation";

@Component({
  selector: "dialog-parent-content",
  templateUrl: "./dialog-parent.html",
  styleUrls: ['./dialog-parent.css']
})
export class DialogParentComponent implements OnInit {
  jobTitle: any = '';
  jobId: any = '';
  jobParent: any = '';
  domainName: any = '';
  progressDetail: any;
  newJobSequence: any = [];
  informationData: any = [];
  createdBy: any = "";
  pubName: any = "";
  picEmail: any = "";
  picName: any = "";
  newDomList: any = [];
  excelLink: any = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderTrackingService: OrderTrackingService,
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog
  ) {

  }

  finalObj = [];

  detailToFill = [];

  getPrereq(params) {
    if (!params.preRequisite) {
      return [];
    }
    try {
      let parser = JSON.parse(params.preRequisite);
      let finArr = [];
      for (let test of parser) {
        let findObject = this.data.lstOfJob.find((e) => {return e.id == test});
        if (findObject) {
          finArr.push({
            name: findObject.TemplateChild.jobName,
            status: findObject.status
          });
        }
      }
      return finArr;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async editJob(jobToEdit) {
    let createObject = {
      title: jobToEdit.TemplateChild.jobName,
      modules: 'editCurrentJob',
      jobId: jobToEdit.id,
      dataAssigned: jobToEdit.dataAssigned,
      dataToShow: [
        {
          "title": "Person In Charge",
          "type": "longString",
          "value": jobToEdit.pic.name
        },
        {
          "title": "Request Data",
          "type": "json",
          "value": jobToEdit.dataAssigned
        },
        {
          "title": "Job Complete Time",
          "type": "longString",
          "value": jobToEdit.strfiedTimeGap
        }
      ]
    }
    const dialogRef = this.dialog.open(DynamicConfirmationDialog, {
      data: createObject,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  async ngOnInit() {
    let newArrayOfDomain = [];
    this.dialogRef.updateSize('85vw', '85vh');
    this.excelLink = this.data.spreadsheetUrl;
    this.jobTitle = this.data.JobTemplate.name;
    // this.jobParent = this.data.jobParent;
    this.jobId = this.data.id;
    this.createdBy = this.data.initiator;
    if (this.data.domName) {
      for (let x of this.data.domName) {
        let findInNewArrOfDom = newArrayOfDomain.find((e) => {return e.pub == x.publisherIdentifier});

        if (!findInNewArrOfDom) {
          let newObj = {
            pub: x.pubDetail.publisherName,
            data: [{
              name: x.publisherUrl,
              mcmNumber: x.websiteMcm
            }]
          }
          newArrayOfDomain.push(newObj);
        } else {
          findInNewArrOfDom.data.push({
            name: x.publisherUrl,
            mcmNumber: x.websiteMcm
          });
        }
        this.newDomList = newArrayOfDomain;

        if (!this.domainName.includes(x.publisherUrl)) {
          this.domainName += `(${x.pubDetail.publisherName})` + x.publisherUrl + '  ';
        }
        if (!this.pubName.includes(x.pubDetail.publisherName)) {
          this.pubName += x.pubDetail.publisherName + '   ';
        }

        if (!this.picEmail.includes(x.pubDetail.picEmail)) {
          this.picEmail += `(${x.pubDetail.publisherName})${x.pubDetail.picEmail} `
        }

        if (!this.picName.includes(x.pubDetail.picName)) {
          this.picName += `(${x.pubDetail.publisherName})${x.pubDetail.picName} `
        }
      }
    }


    for (let x of this.data.lstOfJob) {
      if (x.dataAssigned && x.dataAssigned != "") {
        let info = JSON.parse(x.dataAssigned);
        let informant = x;
        let obj = {
          info: info,
          informant: informant
        }
        this.informationData.push(obj);
      }
    }

    // this.progressDetail = await this.orderTrackingService.getJobFromParent(this.jobParent);
    this.progressDetail = this.data;
    let nopreReq = this.progressDetail.lstOfJob.filter((e) => e.preRequisite == null);
    let depth = 0;
    let notFound = [];
    let found = [];

    for (let data of nopreReq) {
      let obj = data;
      obj['depth'] = depth;
      this.finalObj.push(obj);
    }

    if (nopreReq.length > 0 )depth++;

    for (let data of this.progressDetail.lstOfJob) {
      if (data.preRequisite == null) {
        continue;
      } else {
        let preReq = JSON.parse(data.preRequisite);
        let eligible = true;

        for (let detail of preReq) {
          let check = nopreReq.find((e)=> detail == e.id);
          if (!check) {
            eligible = false;
          }
        }

        if (eligible) {
          let check2 = found.find((e) => e.id == data.id);
          if (!check2) {
            found.push(data);
          }
        } else {
          let check2 = notFound.find((e) => e.id == data.id);
          if (!check2) {
            notFound.push(data);
          }
        }
      }
    }

    let ctr = 0;
    do {
      let checkState = {
        finalObj: JSON.parse(JSON.stringify(this.finalObj)),
        found: JSON.parse(JSON.stringify(found)),
        notFound: JSON.parse(JSON.stringify(notFound))
      };
      ctr++;
      if (ctr == 1000) {
        console.log("ERROR! INFINITY LOOP");
        break;
      }
      for (let data of found) {
        let obj = data;
        let find = this.finalObj.find((e) => e.id == data.id);
        if (!find) {
          obj['depth'] = depth;
          this.finalObj.push(obj);
        }

      }
      if (found.length > 0) depth++;
      found = [];
      for (let data of notFound) {
        let check = this.finalObj.find((e) => e.id == data.id);
        if (check) {
          let checkIdx = notFound.findIndex((e) => e.id == data.id);
          notFound.splice(checkIdx, 1);
        }

        for (let detail of notFound) {
          let preReq = JSON.parse(detail.preRequisite);
          for (let prerequisite of preReq) {
            let parentObject = this.finalObj.find((e) => e.id == prerequisite);
            if (parentObject) {
              let check3 = found.find((e) => e.id == detail.id);
              if (!check3) {
                found.push(detail);
              }
            }
          }
        }
      }
    } while(notFound.length > 0);

    for (let obj of this.finalObj) {
      if (!this.newJobSequence[obj.depth]) {
        this.newJobSequence[obj.depth] = [];
      }
      this.newJobSequence[obj.depth].push(obj);
    }
  }

  async completeJob() {
    let strfiedJson = null;
    if (this.detailToFill.length > 0) {
      strfiedJson = JSON.stringify(this.detailToFill);
    }
    await this.orderTrackingService.completeJob(this.jobId, strfiedJson);
    location.href = "https://tracking.props.id//dashboard/admin";

  }

  async deleteCurrentJob() {
    await this.orderTrackingService.deleteCurrentJob(this.jobId);
    location.href = "https://tracking.props.id//dashboard/admin";
  }

  getJobClass(param) {
    let status = 0;
    if (param.preRequisite) {
      let arrPrereq = JSON.parse(param.preRequisite);
      for (let data of arrPrereq) {
        let findData = this.progressDetail.lstOfJob.find((e) => data == e.id);
        if (findData.status == 0) {
          return "job-detail-container locked";
        }
      }
    }

    status = param.status;
    if (status == 0) {
      return "job-detail-container uncompleted"
    } else if (status == 1) {
      return "job-detail-container completed";
    } else {
      return "job-detail-container locked";
    }

  }
}
