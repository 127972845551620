import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogJobComponent } from "src/app/components/dialog/dialog-job.component";
import { AuthorizationService } from "src/app/service/authorization.service";
import { HttpSetup } from "src/app/service/httpSetup.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import {MatAccordion} from '@angular/material/expansion';
import { PublisherService } from "src/app/service/publisher.service";

@Component({
  selector: "app-adunitsearch",
  templateUrl: "./adunitsearch.component.html",
})
export class AdunitSearchComponent implements OnInit {

  lstOfadslot: any = [];
  newObjOfAdslot: any = [];
  idUser: any;
  selectedPublisher: string;
  numOfPublisher: any = 0;
  arrOfPub = [];
  dropdownList = [];
  dropdownSettings = {};
  //
  lstOfSearchedAdunit: any = [];
  includeAdunits = [];
  excludeAdunits = [];
  selectedItems = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(private pubService: PublisherService, public dialog: MatDialog, public httpSetup: HttpSetup, public authService: AuthorizationService, public router: Router, public actRoute: ActivatedRoute, private toastr: ToastrService, public orderTrackingService: OrderTrackingService) {

  }

  isExistInIncludeSelection(includeAdunits) {
    let search = this.includeAdunits.find((e) => {return e == includeAdunits});
    if (search) {
      return true;
    }
    else {
      return false;
    }
  }

  isExistInExcludeSelection(excludeAdunits) {
    let search = this.excludeAdunits.find((e) => {return e == excludeAdunits});
    if (search) {
      return true;
    }
    else {
      return false;
    }
  }

  async startSearching() {
    let createArrayOfSearchedPublisher = [];

    for (let data of this.selectedItems) {
      createArrayOfSearchedPublisher.push(data.item_text);
    }
    let normalizeArr = [];
    for (let data of this.selectedItems) {
      normalizeArr.push(data.item_text);
    }
    let paramObjects = {
      paramExclude: this.excludeAdunits,
      paramInclude: this.includeAdunits,
      paramPub: normalizeArr
    }

    this.lstOfSearchedAdunit = await this.pubService.searchByAdUnits(paramObjects);
  }

  onPubSelect(ev){}

  onPubDeSelect(ev){}

  getDesktopAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 2});
    return filteredData;
  }

  getMobileAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 1});
    return filteredData;
  }

  getOtherAdslot() {
    let filteredData =  this.lstOfadslot.filter((e) => { return e.type == 3});
    return filteredData;
  }

  addToIncluded(includeAdunits) {
    let search = this.includeAdunits.find((e) => {return e == includeAdunits});
    if (search) {
      let searchIdx = this.includeAdunits.findIndex((e) => {return e == includeAdunits});
      this.includeAdunits.splice(searchIdx, 1);
    } else {
      this.includeAdunits.push(includeAdunits);
    }
  }

  addToExclude(excludeAdunits) {
    let search = this.excludeAdunits.find((e) => {return e == excludeAdunits});
    if (search) {
      let searchIdx = this.excludeAdunits.findIndex((e) => {return e == excludeAdunits});
      this.excludeAdunits.splice(searchIdx, 1);
    } else {
      this.excludeAdunits.push(excludeAdunits);
    }
  }

  reconstructAdslotModules(){
    let arrOfData = [];

    for (let data of this.lstOfadslot) {
      let check = arrOfData.find((e) => {return e.adSlotAlias == data.alias});
      if (!check) {
        arrOfData.push({
          adSlotAlias: data.alias,
          platform: [],
          checked: false,
          classifier: data.classifier
        });
      }
      check = arrOfData.find((e) => {return e.adSlotAlias == data.alias});
      check.platform.push(data);
    }

    let arrOfClassified = [];
    for (let x of arrOfData) {
      let check = arrOfClassified.find((e) => {return e.classifier == x.classifier});
      if (!check) {
        arrOfClassified.push({
          classifier: x.classifier,
          adSlot: [x]
        });
      } else {
        check.adSlot.push(x);
      }
    }

    this.newObjOfAdslot = arrOfClassified;

    // this.newObjOfAdslot.sort((a,b) => {return (a.adSlotAlias > b.adSlotAlias) ? 1 : (a.adSlotAlias < b.adSlotAlias) ? -1 : 0});

    for (let x of this.newObjOfAdslot) {
      x.adSlot.sort((a,b) => {return (a.adSlotAlias > b.adSlotAlias) ? 1 : (a.adSlotAlias < b.adSlotAlias) ? -1 : 0});
    }
  }

  reinstiatePubList(obj) {
    this.dropdownList = [];
    for (let x of obj) {
      let newData = {
        item_id: x.databaseId,
        item_text: x.publisherName
      }
      this.dropdownList.push(newData);
    }
  }


  async ngOnInit() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 90,
      allowSearchFilter: true,
      enableCheckAll: false
    };

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];

    this.orderTrackingService.getAdUnitList().then((res) => {
      this.lstOfadslot = res;
      this.reconstructAdslotModules();
    })
    let pubData = await this.pubService.getAllPublisher();
    this.reinstiatePubList(pubData[0]);
  }
}
