import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-card-dashboard-warning",
  templateUrl: "./card-dashboard-warning.html",
})
export class CardDashboardWarningComponent implements OnInit {
  tempData: any = [
    {
      data: [{
        job: 'taktik.com Impression Drop By 50%'
      },
      {
        job: 'taktik.com Adslot x Impression Drop By 90%'
      },
      {
        job: 'taktik.com Check Data '
      }]
    }
  ]

  constructor() {}

  ngOnInit(): void {}
}
