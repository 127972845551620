import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OrderTrackingService } from "src/app/service/orderTracking.service";

@Component({
  selector: "dynamic-confirmation",
  templateUrl: "./dynamic-confirmation.html",
  styleUrls: ['./dynamic-confirmation.css']
})
export class DynamicConfirmationDialog implements OnInit {
  dataToProcess: any;
  title: any;
  modules: any;
  jobId: any;
  dataAssigned: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderTrackingService: OrderTrackingService,
    public dialogRef: MatDialogRef<any>
  ) {

  }

  getObj(param) {
    try {
      let x = JSON.parse(param);
      return x;
    } catch (e) {
      return false;
    }

  }

  async onSubmit() {
    if (this.modules == 'editCurrentJob') {
      this.orderTrackingService.editCurrentJob(this.jobId, this.dataAssigned).then((resolve) => {
        location.reload();
      }, (reject) => {

      })
    }
  }

  async ngOnInit() {
    this.dataToProcess = this.data.dataToShow;
    this.title = this.data.title;
    this.modules = this.data.modules;
    this.dataAssigned = this.data.dataAssigned;
    this.jobId = this.data.jobId;
    console.log(this.data)
  }

}
