import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "app-admin-navbar-dashboard",
  templateUrl: "./admin-navbar-dashboard.component.html",
})
export class AdminNavbarDashboardComponent implements OnInit {
  constructor(
    private singletonServices: SingletonServices,
    private router: Router
  ) {}

  username: string = "";
  role: string = "";

  logoutFromApplication() {
    let authCode = localStorage.removeItem('authcode');
    this.router.navigate(['/auth/login'])
  }

  ngOnInit(): void {
    this.singletonServices.getUserData().then((res) => {
      this.username = res.employeeDetail.name;
      this.role = res.employeeDetail.group.name;
    })
  }
}
