import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { OrderTrackingService } from "src/app/service/orderTracking.service";
import { SingletonServices } from "src/app/service/singleton.service";

@Component({
  selector: "dialog-job-content",
  templateUrl: "./dialog-job.html",
  styleUrls: ['./dialog-job.css']
})
export class DialogJobComponent implements OnInit {
  jobTitle: any = '';
  jobId: any = '';
  jobParent: any = '';
  jobName: any = '';
  pubName: any = '';
  progressDetail: any;
  newJobSequence: any = [];
  informationData: any = [];
  domainName: any = "";
  createdBy: any = "";
  picEmail: any = "";
  picName: any  = "";
  newDomList: any = [];
  excelLink: any = "";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public orderTrackingService: OrderTrackingService,
    public dialogRef: MatDialogRef<any>,
    private toastr: ToastrService,
    private singletonServices: SingletonServices,
  ) {

  }

  finalObj = [];
  eligibleToProcess = false;
  detailToFill = [];

  listenChange(event, row) {
    row.value = event;
  }

  getPrereq(params) {
    if (!params.preRequisite) {
      return [];
    }
    try {
      let parser = JSON.parse(params.preRequisite);
      let finArr = [];
      for (let test of parser) {
        let findObject = this.progressDetail.lstOfJob.find((e) => {return e.id == test});
        if (findObject) {
          finArr.push({
            name: findObject.TemplateChild.jobName,
            status: findObject.status
          });
        }
      }
      return finArr;
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async ngOnInit() {
    this.excelLink = this.data.JobParent.spreadsheetUrl;
    this.dialogRef.updateSize('85vw', '85vh');
    this.singletonServices.getUserData().then((res) => {
      if (res.employeeDetail.group_id == this.data.PIC) {
        this.eligibleToProcess = true;
      } else {
        this.eligibleToProcess = false;
      }

      if (this.data.status == 1) {
        this.eligibleToProcess = false;
      }
    })

    this.jobTitle = this.data.JobTemplate.name;
    this.createdBy = this.data.JobParent.initiator;
    this.jobParent = this.data.jobParent;
    this.jobId = this.data.id;
    // this.picName = "this.data.JobParent.pubDetail.picName";
    // this.picEmail = "this.data.JobParent.pubDetail.picEmail";
    // this.domainName = "this.data.JobParent.domName ? this.data.JobParent.domName.publisherUrl ? this.data.JobParent.domName.publisherUrl : '' : ''";
    this.jobName = this.data.TemplateChild.jobName;
    this.pubName = this.data.JobParent.domName ? this.data.JobParent.domName.publisherIdentifier ? this.data.JobParent.domName.publisherIdentifier : '' : '';
    let checker = this.data.TemplateChild.requestData;

    for (let rowOutputs of this.data.JobParent.domName) {
      if (!this.picName.includes(rowOutputs.pubDetail.picName)) {
        this.picName += `(${rowOutputs.pubDetail.publisherName})` + rowOutputs.pubDetail.picName + '  ';
      }
      if (!this.picEmail.includes(rowOutputs.pubDetail.picEmail)) {
        this.picEmail += `(${rowOutputs.pubDetail.publisherName})` + rowOutputs.pubDetail.picEmail + '  ';
      }
      this.domainName += rowOutputs.publisherUrl + '  ';
    }
    if (checker && (checker != '')) {
      try {
        let getDetailToFill = JSON.parse(this.data.TemplateChild.requestData);
        for (let data in getDetailToFill) {
          let createObject: any = {};
          createObject['value'] = '';
          createObject['title'] = data;
          createObject['type'] = getDetailToFill[data];
          this.detailToFill.push(createObject);
        }
      } catch (e) {
        console.log(e);
      }
    }

    this.progressDetail = await this.orderTrackingService.getJobFromParent(this.jobParent);
    let nopreReq = this.progressDetail.lstOfJob.filter((e) => e.preRequisite == null);

    for (let x of this.progressDetail.lstOfJob) {
      if (x.dataAssigned && x.dataAssigned != "") {
        let info = JSON.parse(x.dataAssigned);
        let informant = x;
        let obj = {
          info: info,
          informant: informant
        }
        this.informationData.push(obj);
      }
    }

    let depth = 0;
    let notFound = [];
    let found = [];

    for (let data of nopreReq) {
      let obj = data;
      obj['depth'] = depth;
      this.finalObj.push(obj);
    }
    depth++;
    // if (found.length > 0) depth++;
    for (let data of this.progressDetail.lstOfJob) {
      if (data.preRequisite == null) {
        continue;
      } else {
        let preReq = JSON.parse(data.preRequisite);
        let eligible = true;

        for (let detail of preReq) {
          let check = nopreReq.find((e)=> detail == e.id);
          if (!check) {
            eligible = false;
          }
        }

        if (eligible) {
          let check2 = found.find((e) => e.id == data.id);
          if (!check2) {
            found.push(data);
          }
        } else {
          let check2 = notFound.find((e) => e.id == data.id);
          if (!check2) {
            notFound.push(data);
          }
        }
      }
    }


    let ctr = 0;
    do {
      let checkState = {
        finalObj: JSON.parse(JSON.stringify(this.finalObj)),
        found: JSON.parse(JSON.stringify(found)),
        notFound: JSON.parse(JSON.stringify(notFound))
      };
      ctr++;
      if (ctr == 1000) {
        console.log("ERROR! INFINITY LOOP");
        break;
      }
      for (let data of found) {
        let obj = data;
        let find = this.finalObj.find((e) => e.id == data.id);
        if (!find) {
          obj['depth'] = depth;
          this.finalObj.push(obj);
        }
      }
      if (found.length > 0) depth++;
      found = [];
      for (let data of notFound) {
        let check = this.finalObj.find((e) => e.id == data.id);
        if (check) {
          let checkIdx = notFound.findIndex((e) => e.id == data.id);
          notFound.splice(checkIdx, 1);
        }

        for (let detail of notFound) {
          let preReq = JSON.parse(detail.preRequisite);

          for (let prerequisite of preReq) {
            let parentObject = this.finalObj.find((e) => e.id == prerequisite);
            if (parentObject) {
              let check3 = found.find((e) => e.id == detail.id);
              if (!check3) {
                found.push(detail);
              }
            }
          }
        }
      }
    } while(notFound.length > 0);

    for (let obj of this.finalObj) {
      if (!this.newJobSequence[obj.depth]) {
        this.newJobSequence[obj.depth] = [];
      }
      this.newJobSequence[obj.depth].push(obj);
    }

    let newArrayOfDomain = [];
    if (this.data.JobParent.domName) {
      for (let x of this.data.JobParent.domName) {
        let findInNewArrOfDom = newArrayOfDomain.find((e) => {return e.pub == x.publisherIdentifier});
        if (!findInNewArrOfDom) {
          let newObj = {
            pub: x.pubDetail.publisherName,
            data: [{
              name: x.publisherUrl,
              mcmNumber: x.websiteMcm
            }]
          }
          newArrayOfDomain.push(newObj);
        } else {
          findInNewArrOfDom.data.push({
            name: x.publisherUrl,
            mcmNumber: x.websiteMcm
          });
        }
        this.newDomList = newArrayOfDomain;

        if (!this.domainName.includes(x.publisherUrl)) {
          this.domainName += `(${x.pubDetail.publisherName})` + x.publisherUrl + '  ';
        }
        if (!this.pubName.includes(x.pubDetail.publisherName)) {
          this.pubName += x.pubDetail.publisherName + '   ';
        }

        if (!this.picEmail.includes(x.pubDetail.picEmail)) {
          this.picEmail += `(${x.pubDetail.publisherName})${x.pubDetail.picEmail} `
        }

        if (!this.picName.includes(x.pubDetail.picName)) {
          this.picName += `(${x.pubDetail.publisherName})${x.pubDetail.picName} `
        }
      }
    }

  }

  async completeJob() {
    let strfiedJson = null;
    if (this.detailToFill.length > 0) {
      for (let data of this.detailToFill) {
        if (data.type == "boolean") {
          if (data.value == "") {
            data.value = false;
          }
        } else if (data.type == "requiredstring") {
          if (data.value == "") {
            this.toastr.error(`Field ${data.title} wajib diisi!`, 'Gagal!', {
              timeOut: 3000
            });
            return;
          }
        } else if (data.type == "requiredLongString") {
          if (data.value == "") {
            this.toastr.error(`Field ${data.title} wajib diisi!`, 'Gagal!', {
              timeOut: 3000
            });
            return;
          }
        } else if (data.type == "requiredBoolean") {
          if (data.value == "" || data.value == false) {
            this.toastr.error(`Field ${data.title} harus dicentang!`, 'Gagal!', {
              timeOut: 3000
            });
            return;
          }
        }
      }
      strfiedJson = JSON.stringify(this.detailToFill);
    }
    await this.orderTrackingService.completeJob(this.jobId, strfiedJson);
    location.href = "https://tracking.props.id/dashboard/admin";

  }

  getJobClass(param) {
    let status = 0;
    if (param.preRequisite) {
      let arrPrereq = JSON.parse(param.preRequisite);
      for (let data of arrPrereq) {
        let findData = this.progressDetail.lstOfJob.find((e) => data == e.id);
        if (findData.status == 0) {
          return "job-detail-container locked";
        }
      }
    }

    status = param.status;
    if (status == 0) {
      return "job-detail-container uncompleted"
    } else if (status == 1) {
      return "job-detail-container completed";
    } else {
      return "job-detail-container locked";
    }

  }
}
