import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-card-new-order-bulk",
  templateUrl: "./card-new-order-bulk.html",
})
export class CardNewOrderBulkComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
