import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpSetup } from "./httpSetup.service";


@Injectable()
export class AuthorizationService {
    constructor(
        private httpClient: HttpClient,
        private httpSetup: HttpSetup
    ) {

    }
    async login(userName: any, password: any) {
        return new Promise((resolve, reject) => {
            this.httpClient.post<any>(this.httpSetup.getUrl('auth/login'), {
                username: userName,
                password: password
            },{headers: this.httpSetup.setHeaders()}).subscribe(data => {
              resolve(data);
            },
            error => {
              reject(error);
            })
        })

    }

    async userInfo() {
        return new Promise((resolve) => {
            let authCode = localStorage.getItem('authcode');
            if (authCode) {
                this.httpSetup.setAuthorization(authCode);
                this.httpClient.get<any>(this.httpSetup.getUrl('userinfo'),{headers: this.httpSetup.setHeaders()}).subscribe(data => {
                    resolve(data);
                })
            }
        })

    }
}
